import "@babel/polyfill";
import { ErrorBoundary } from "react-error-boundary";
import { ConnectedRouter } from "connected-react-router";
import React, { useState, useEffect } from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { I18nextProvider } from "react-i18next";
import App from "@app/app";
import i18n from "./services/language";
import configureStore from "./redux/configureStore";
import history from "@app/utils/history";

import { ConfigProvider, theme } from "antd";
import css from "styled-jsx/css";

const { defaultAlgorithm, darkAlgorithm } = theme;

function ErrorFallback({ error, resetErrorBoundary }) {
  return (
    <div className="alert-boundary">
      <p>Something went wrong:</p>
      <pre>{error.message}</pre>
      <button onClick={resetErrorBoundary}>Try again</button>
    </div>
  );
}

const lightThemeToken = {
  colorPrimary: lightTheme["--primary-color"],
  colorBgBase: lightTheme["--background-color"],
  colorTextBase: lightTheme["--primary-text-color"],
  colorError: lightTheme["--error-color"],
};

const darkThemeToken = {
  colorPrimary: darkTheme["--primary-color"],
  colorBgBase: darkTheme["--background-color"],
  colorTextBase: darkTheme["--primary-text-color"],
  colorError: darkTheme["--error-color"],
};

const styles = css.global`
  .ant-layout {
    background-color: var(--top-background-color);
  }
  body {
    background-color: var(--background-color);
  }
  .primary-color {
    color: var(--primary-color);
  }
  .second-color {
    color: var(--second-color);
  }
  .third-color {
    color: var(--third-color);
  }
  .second-text-color {
    color: var(--second-text-color);
  }
  .red-color {
    color: var(--red-color);
  }
  .primary-bg {
    background: var(--primary-color);
  }
  .second-bg {
    background: var(--second-color);
  }
  .third-bg {
    background: var(--third-color);
  }
  .red-bg {
    background: var(--red-color);
  }
  .background-color {
    background-color: var(--background-color);
  }
  .background-color-secondary {
    background-color: var(--background-color-secondary);
  }
  .background-color-tertiary {
    background-color: var(--background-color-tertiary);
  }
  .title-1 {
    font-size: 40px;
  }
  .title-2 {
    font-size: 32px;
  }
  .title-3 {
    font-size: 28px;
  }
  .title-4 {
    font-size: 24px;
  }
  .title-5 {
    font-size: 20px;
  }
  .title-6 {
    font-size: 16px;
  }
  .pa {
    font-size: 16px;
  }
  .pa-17 {
    font-size: 17px;
  }
  .pa-18 {
    font-size: 18px;
  }
  .pa-10 {
    font-size: 10px;
  }
  .pa-11 {
    font-size: 11px;
  }
  .pa-12 {
    font-size: 12px;
  }
  .pa-13 {
    font-size: 13px;
  }
  .pa-14 {
    font-size: 14px;
  }
  .pa-15 {
    font-size: 15px;
  }
  .pa-8 {
    font-size: 8px;
  }
  .href {
    color: #f91a6c;
  }
`;

const StyleApp = ({ theme, children }) => (
  <>
    {children}
    <style jsx global>{`
      :root {
        ${theme
          ? Object.entries(theme)
              .map(([key, value]) => `${key}: ${value};`)
              .join(" ")
          : ""}
      }
    `}</style>
    <style jsx global>
      {styles}
    </style>
  </>
);

const MOUNT_NODE = document.getElementById("root-app");

import "@app/components/core/css/antd.less";
import "@app/components/core/css/core.scss";

import vi from "antd/es/locale/en_US";
import { darkTheme, lightTheme } from "./configs";

const MainApp = () => {
  const [isDarkMode, setIsDarkMode] = useState(false);
  const [collapsed, setIsCollapsedMode] = useState(false);

  useEffect(() => {
    // Check local storage for theme preference
    const savedTheme = localStorage.getItem("isDarkMode");

    const isCollapsed = localStorage.getItem("isCollapsedMode");

    if (isCollapsed) {
      setIsCollapsedMode(isCollapsed === "true");
    }

    if (savedTheme !== null) {
      setIsDarkMode(savedTheme === "true");
    } else {
      // Default to system theme
      const mediaQuery = window.matchMedia("(prefers-color-scheme: dark)");
      setIsDarkMode(mediaQuery.matches);

      const handler = (e) => setIsDarkMode(e.matches);
      mediaQuery.addEventListener("change", handler);
      return () => mediaQuery.removeEventListener("change", handler);
    }
  }, []);

  const toggleTheme = () => {
    setIsDarkMode((prevMode) => {
      const newMode = !prevMode;
      localStorage.setItem("isDarkMode", newMode);
      return newMode;
    });
  };

  const toggleCollapsed = () => {
    setIsCollapsedMode((prevMode) => {
      const newMode = !prevMode;
      localStorage.setItem("isCollapsedMode", newMode);
      return newMode;
    });
  };

  return (
    <ConfigProvider
      locale={vi}
      theme={{
        algorithm: isDarkMode ? darkAlgorithm : defaultAlgorithm,
        token: isDarkMode ? darkThemeToken : lightThemeToken,
      }}
    >
      <I18nextProvider i18n={i18n}>
        <ErrorBoundary
          FallbackComponent={ErrorFallback}
          onReset={() => {
            window.location.reload();
          }}
        >
          <StyleApp theme={isDarkMode ? darkTheme : lightTheme}>
            <App
              isDarkMode={isDarkMode}
              toggleTheme={toggleTheme}
              collapsed={collapsed}
              toggleCollapsed={toggleCollapsed}
              history={history}
            />
          </StyleApp>
        </ErrorBoundary>
      </I18nextProvider>
    </ConfigProvider>
  );
};

const render = () => {
  ReactDOM.render(
    <Provider store={configureStore}>
      <ConnectedRouter history={history}>
        <MainApp />
      </ConnectedRouter>
    </Provider>,
    MOUNT_NODE
  );
};

render();
