import * as React from "react";
import css from "styled-jsx/css";
import { connect } from "react-redux";
import { Layout, Dropdown, Avatar, Menu, Button, theme } from "antd";
import {
  DownOutlined,
  MoonOutlined,
  MoonFilled,
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  UploadOutlined,
  UserOutlined,
  VideoCameraOutlined,
  AppstoreOutlined,
  PushpinOutlined,
  EditOutlined,
  BellOutlined,
} from "@ant-design/icons";
import { Link, NavLink, withRouter } from "react-router-dom";
import { loadUser } from "@app/redux/actions";
import { LoadingPage } from "@app/components/core/loading";
import { LocalStore } from "@app/utils/local-storage";
import { envName } from "@app/configs";
import AppsIcon from "@app/resources/images/apps_icon.svg";
import SelectedAppsIcon from "@app/resources/images/selected_apps_icon.svg";
import SelectedAppsIconDark from "@app/resources/images/selected_apps_icon_dark.svg";
import PWAIcon from "@app/resources/images/pwa_icon.svg";
import SelectedPWAIcon from "@app/resources/images/selected_pwa_icon.svg";
import SelectedPWAIconDark from "@app/resources/images/selected_pwa_icon_dark.svg";
import PushIcon from "@app/resources/images/push_icon.svg";
import SelectedPushIcon from "@app/resources/images/selected_push_icon.svg";
import SelectedPushIconDark from "@app/resources/images/selected_push_icon_dark.svg";
import EditorIcon from "@app/resources/images/editor.svg";
import SelectedEditorIcon from "@app/resources/images/selected_editor_icon.svg";
import SelectedEditorIconDark from "@app/resources/images/selected_editor_icon_dark.svg";

const { Header, Content, Sider } = Layout;

const Action = ({ logout, userName = "", isDarkMode, toggleTheme }) => (
  <Dropdown
    menu={{
      items: [
        {
          key: "1",
          label: <Link to="/profile">Edit profile</Link>,
        },
        {
          key: "2",
          label: "Log out",
          onClick: logout,
        },
      ],
    }}
    trigger={["click"]}
  >
    <div
      className="ant-dropdown-link flex items-center"
      onClick={(e) => e.preventDefault()}
    >
      <span style={{ marginLeft: 14 }} className="flex items-center">
        <span className="user-name" style={{ paddingRight: 32 }}>
          {userName}
        </span>
        <DownOutlined width={24} height={24} />
      </span>
    </div>
  </Dropdown>
);

const styles = css.global`
  .undefined {
    padding: 0 !important;
  }
  .site-layout-background.header {
    height: 56px;
    border-bottom: solid 1px var(--second-color);
    background-color: var(--background-color);
  }
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .header-actions {
    display: flex;
    align-items: center;
    gap: 16px; /* Adjust the gap as needed */
  }

  .header-actions .ant-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    background: transparent;
    color: #9e9e9e;
  }

  .header-actions .ant-btn:hover {
    color: var(--primary-text-color);
  }

  .header .user-name {
    font-size: 13px;
    line-height: 1.38;
    letter-spacing: 0.3px;
  }
  .header .my-avatar {
    width: 40px;
    height: 40px;
    overflow: hidden;
    border-radius: 40px;
  }
  .header .logo {
    margin-right: 37px;
  }
  .header .logo img {
    width: 110px;
  }
  .header .list-menu {
    display: flex;
    align-items: center;
    flex: 1;
  }
  .header .list-menu li {
    list-style: none;
  }
  .header .list-menu li a {
    font-size: 10px;
    font-weight: bold;
    line-height: 1.2;
    letter-spacing: 1.54px;
    position: relative;
    padding: 0 18px;
    height: 56px;
    display: flex;
    align-items: center;
    color: #9e9e9e;
  }
  .header .list-menu li a i {
    font-size: 16px !important;
    color: #9e9e9e;
    margin-right: 13px;
  }
  .header .list-menu li a.active {
    color: var(--primary-text-color);
  }
  .header .list-menu li a.active i {
    color: var(--primary-text-color);
  }
  .header .list-menu li a.active:after {
    width: 100%;
    height: 3px;
    background-color: var(--primary-color);
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
  }
`;

const DefaultMain = ({
  children,
  user,
  collapsed,
  isDarkMode,
  toggleCollapsed,
  history,
}) => {
  const logOut = () => {
    LocalStore.local.remove(`${envName}-uuid`);
    loadUser();
    history.push("/login");
  };

  const pathname = history.location.pathname;
  const onMenuClick = (el) => {
    history.push(el.key);
  };
  const getProfile = () => {};

  return (
    <div>
      <h4
        style={{
          marginLeft: "1em",
          marginTop: "0.5em",
          marginBottom: "0.5em",
          color: "#e5e5e5",
        }}
      >
        {window.location.host}
      </h4>
      <Layout
        theme={isDarkMode ? "dark" : "light"}
        style={{ minHeight: "100vh" }}
        className="site-layout"
      >
        <Sider
          theme={isDarkMode ? "dark" : "light"}
          trigger={null}
          collapsible
          collapsed={collapsed}
        >
          <div className="demo-logo-vertical" />
          <Menu
            theme={isDarkMode ? "dark" : "light"}
            mode="inline"
            defaultSelectedKeys={[pathname]}
            items={[
              {
                key: "/",
                icon: <AppstoreOutlined />,
                label: "Pwas",
                onClick: (el) => onMenuClick(el),
              },
              {
                key: "/push",
                icon: <BellOutlined />,
                label: "Push",
                onClick: (el) => onMenuClick(el),
              },
              {
                key: "/editor",
                icon: <EditOutlined />,
                label: "Editor",
                onClick: (el) => onMenuClick(el),
              },
              {
                key: "/profile",
                icon: <UserOutlined />,
                label: "Profile",
                onClick: (el) => onMenuClick(el),
              },
              {
                key: "/logout",
                icon: <UserOutlined />,
                label: "Log Out",
                onClick: () => {
                  logOut();
                },
                style:{
                  top: "25rem"
                }
              },
            ]}
          />
        </Sider>
        <Layout theme={isDarkMode ? "dark" : "light"}>
          <Header
            style={{
              padding: 0,
              backgroundColor: isDarkMode
                ? "var(--top-background-color)"
                : "var(--third-color)",
            }}
          >
            <Button
              type="text"
              icon={collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
              onClick={() => toggleCollapsed(!collapsed)}
              style={{
                fontSize: "16px",
                width: 64,
                height: 64,
              }}
            />
          </Header>
          <Content
            theme={isDarkMode ? "dark" : "light"}
            className={`site-layout-background ${!user ? "undefined" : ""}`}
            style={{
              minHeight: 280,
            }}
          >
            {children}
          </Content>
        </Layout>
      </Layout>
    </div>
  );
};

const mapDispatchToProps = {
  loadUser,
};

const mapStatesToProps = (states) => ({
  user: states.global.user,
});

export default connect(
  mapStatesToProps,
  mapDispatchToProps
)(withRouter(DefaultMain));
