import * as React from "react";

import MakeRoute from "./router";
import { loadUser } from "@app/redux/actions";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

const App = ({ loadUser, isDarkMode, toggleTheme, collapsed, toggleCollapsed, history }) => {
  React.useEffect(() => {
    loadUser();
  }, []);

  return (
    <MakeRoute
      isDarkMode={isDarkMode}
      toggleTheme={toggleTheme}
      collapsed={collapsed}
      toggleCollapsed={toggleCollapsed}
      history={history}
    />
  );
};

const mapDispatchToProps = {
  loadUser,
};

export default connect(null, mapDispatchToProps)(withRouter(App));
