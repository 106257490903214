import * as React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import DefaultMain from "@app/components/main";
import PrivateRoute from "@app/components/private-route";
import { LoadingPage } from "@app/components/core/loading";

const routes = [
  {
    name: "Login",
    component: React.lazy(() =>
      Promise.all([
        import("@app/modules/auth/components/login"),
        new Promise((resolve) => setTimeout(resolve, 1000)),
      ]).then(([moduleExports]) => moduleExports)
    ),
    path: "/login",
    id: "login",
    exact: true,
    private: false,
    can: "view",
  },
  {
    name: "Sign up",
    component: React.lazy(() =>
      Promise.all([
        import("@app/modules/auth/components/sign-up"),
        new Promise((resolve) => setTimeout(resolve, 1000)),
      ]).then(([moduleExports]) => moduleExports)
    ),
    path: "/sign-up",
    id: "sign-up",
    exact: true,
    private: false,
    can: "view",
  },
  // {
  //   name: "request pass",
  //   component: React.lazy(() => Promise.all([
  //     import('@app/modules/auth/components/request-pass'),
  //     new Promise((resolve) => setTimeout(resolve, 1000)),
  //   ])
  //     .then(([moduleExports]) => moduleExports)),
  //   path: '/request-password',
  //   id: 'request-password',
  //   exact: true,
  //   private: false,
  //   can: 'view'
  // },
  // {
  //   name: "reset-password-success",
  //   component: React.lazy(() => Promise.all([
  //     import('@app/modules/auth/components/success'),
  //     new Promise((resolve) => setTimeout(resolve, 1000)),
  //   ])
  //     .then(([moduleExports]) => moduleExports)),
  //   path: '/success',
  //   id: 'success',
  //   exact: true,
  //   private: false,
  //   can: 'view'
  // },
  {
    name: "Apps",
    component: React.lazy(() =>
      Promise.all([
        import("@app/modules/apps"),
        new Promise((resolve) => setTimeout(resolve, 1000)),
      ]).then(([moduleExports]) => moduleExports)
    ),
    path: "/",
    id: "apps",
    exact: true,
    private: true,
    can: "view",
  },
  {
    name: "Push",
    component: React.lazy(() =>
      Promise.all([
        import("@app/modules/push"),
        new Promise((resolve) => setTimeout(resolve, 1000)),
      ]).then(([moduleExports]) => moduleExports)
    ),
    path: "/push",
    id: "push",
    exact: true,
    private: true,
    can: "view",
  },
  {
    name: "Preview",
    component: React.lazy(() =>
      Promise.all([
        import("@app/modules/preview"),
        new Promise((resolve) => setTimeout(resolve, 1000)),
      ]).then(([moduleExports]) => moduleExports)
    ),
    path: "/app/preview",
    id: "preview",
    exact: true,
    private: true,
    can: "view",
  },
  {
    name: "My Profile",
    component: React.lazy(() =>
      Promise.all([
        import("@app/modules/user/component/profile"),
        new Promise((resolve) => setTimeout(resolve, 1000)),
      ]).then(([moduleExports]) => moduleExports)
    ),
    path: "/profile",
    id: "profile",
    exact: true,
    private: true,
    can: "edit",
  },
  {
    name: "Editor",
    component: React.lazy(() =>
      Promise.all([
        import("@app/modules/editor"),
        new Promise((resolve) => setTimeout(resolve, 1000)),
      ]).then(([moduleExports]) => moduleExports)
    ),
    path: "/editor",
    id: "editor",
    exact: true,
    private: true,
    can: "view",
  },
  {
    name: "Apps",
    component: React.lazy(() =>
      Promise.all([
        import("@app/modules/apps/components/stats"),
        new Promise((resolve) => setTimeout(resolve, 1000)),
      ]).then(([moduleExports]) => moduleExports)
    ),
    path: "/stats/:id",
    id: "/stats:id",
    exact: true,
    private: true,
    can: "view",
  },
];

const MakeRoute = ({
  isDarkMode,
  toggleTheme,
  collapsed,
  toggleCollapsed,
  history,
}) => (
  <DefaultMain
    isDarkMode={isDarkMode}
    toggleTheme={toggleTheme}
    collapsed={collapsed}
    toggleCollapsed={toggleCollapsed}
    history={history}
  >
    <React.Suspense fallback={<LoadingPage />}>
      <Switch>
        {routes.map((route) =>
          !route?.private ? (
            <Route
              exact={route.exact || false}
              path={route.path}
              key={route.id}
              render={(props) => {
                return <route.component {...props} />;
              }}
            />
          ) : (
            <PrivateRoute
              can={route.can}
              exact={route.exact || false}
              path={route.path}
              key={route.id}
              component={route.component}
              {...(route.id === "profile" ? { isDarkMode, toggleTheme } : {})}
            />
          )
        )}
        <Route render={() => <div>Not Found</div>} />
      </Switch>
    </React.Suspense>
  </DefaultMain>
);

export default MakeRoute;
